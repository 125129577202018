<template>
  <v-container id="crud" fluid tag="section">
    <v-card>
      <v-toolbar flat color="white">
        <v-toolbar-title>Listado de {{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-data-table
        :headers="showHeaders"
        :items="filteredData"
        sort-by="ref_pedido"
        class="elevation-4 data__table"
      >
        <template v-slot:[`header.ref_pedido`]="{ header }">
          <filter-component
            v-bind:header="{ header }"
            v-bind:filters="filters"
          ></filter-component>
        </template>

        <template v-slot:[`header.estado`]="{ header }">
          <filter-component
            v-bind:header="{ header }"
            v-bind:filters="filters"
          ></filter-component>
        </template>

        <template v-slot:[`header.tipo`]="{ header }">
          <filter-component
            v-bind:header="{ header }"
            v-bind:filters="filters"
          ></filter-component>
        </template>

        <template v-slot:[`header.entidad`]="{ header }">
          <filter-component
            v-bind:header="{ header }"
            v-bind:filters="filters"
          ></filter-component>
        </template>

        <template v-slot:[`header.ente`]="{ header }">
          <filter-component
            v-bind:header="{ header }"
            v-bind:filters="filters"
          ></filter-component>
        </template>

        <template v-slot:[`header.cliente_id`]="{ header }">
          <filter-component
            v-bind:header="{ header }"
            v-bind:filters="filters"
          ></filter-component>
        </template>

        <template v-slot:[`header.fecha`]="{ header }">
          <filter-component
            v-bind:header="{ header }"
            v-bind:filters="filters"
          ></filter-component>
        </template>

        <template v-slot:[`header.total`]="{ header }">
          <filter-component
            v-bind:header="{ header }"
            v-bind:filters="filters"
          ></filter-component>
        </template>
        <template v-slot:[`header.metodo_pago`]="{ header }">
          <filter-component
            v-bind:header="{ header }"
            v-bind:filters="filters"
          ></filter-component>
        </template>

        <template v-slot:[`item.ref_pedido`]="{ item }">
          <a
            @click="viewDetalle(item)"
            class="text--primary"
            style="cursor: pointer; text-decoration: underline"
          >
            {{ item.ref_pedido }}
          </a>
        </template>

        <template v-slot:[`item.estado`]="{ item }">
          <div :class="$getCssTransacciones(item.estado)" dark>
            {{ item.estado }}
          </div>
        </template>

        <template v-slot:[`item.fecha`]="{ item }">
          {{ $formatDate(item.fecha) }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-center align-center">
            <v-icon
              small
              :color="$cv('btnEliminar')"
              title="Cancelar Pedido"
              @click="alert('Código en proceso', 'Funcionalidad sin terminar')"
            >
              mdi-delete
            </v-icon>
            <v-icon
              small
              color="grey"
              title="Ver Factura Reserva Borrador"
              @click="viewFactura(item, 'borrador')"
            >
              mdi-file-document
            </v-icon>

            <v-icon
              v-if="item.estado === 'Facturado'"
              small
              color="black"
              title="Ver Factura Definitiva"
              @click="viewFactura(item, 'definitiva')"
            >
              mdi-file-document
            </v-icon>

            <v-icon
              small
              :color="$cv('btnEliminar')"
              title="Borrar"
              @click="alert('Código en proceso', 'Funcionalidad sin terminar')"
            >
              mdi-delete
            </v-icon>
          </div>
        </template>

        <template v-slot:top>
          <v-toolbar flat color="white" class="pr-2 mt-6">
            <v-row>
              <v-col sm="3" class="pt-20">
                <v-text-field
                  v-model="filters.ref_pedido"
                  append-icon="mdi-magnify"
                  label="Buscar por Ref. Pedido"
                  single-line
                  hide-details
                  @click:append="clearField('ref_pedido')"
                ></v-text-field>
              </v-col>

              <v-col sm="3" class="pt-20">
                <v-text-field
                  v-model="filters.entidad"
                  append-icon="mdi-magnify"
                  label="Buscar por Entidad"
                  single-line
                  hide-details
                  @click:append="clearField('entidad')"
                ></v-text-field>
              </v-col>

              <v-col sm="3" class="pt-20">
                <v-menu
                  ref="startDateMenu"
                  v-model="startDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="filters.startDate"
                      label="Fecha Inicio"
                      prepend-icon="mdi-calendar"
                      append-icon="mdi-close-circle"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:append="clearStartDate"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filters.startDate"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col sm="3" class="pt-20">
                <v-menu
                  ref="endDateMenu"
                  v-model="endDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="filters.endDate"
                      label="Fecha Fin"
                      prepend-icon="mdi-calendar"
                      append-icon="mdi-close-circle"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:append="clearEndDate"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filters.endDate"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-card>

    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      <div v-html="text"></div>

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <vue-confirm-dialog></vue-confirm-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

function title() {
  return "Transacciones";
}

export default {
  data: (vm) => ({
    snackbar: false,
    text: "Registro Insertado",

    color: "success",
    timeout: 4000,
    title: title(),
    search: "",
    valid: true,
    editedItem: {
      nombre: "",
      id: "",
    },
    defaultItem: {
      nombre: "",
      id: "",
    },

    dialog: false,
    readonly: true,
    readOnlySubtotal: true,
    headers: [
      { text: "Ref. Pedido", filterable: true, value: "ref_pedido" },
      { text: "Estado", filterable: true, value: "estado" },
      { text: "Tipo", filterable: true, value: "tipo" },
      { text: "Entidad", filterable: true, value: "entidad" },
      { text: "Ente", filterable: true, value: "ente" },
      { text: "Cliente", filterable: true, value: "cliente_id" },
      { text: "Fecha", filterable: true, value: "fecha" },
      { text: "Total", filterable: true, value: "total" },
      { text: "Método de pago", filterable: true, value: "metodo_pago" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    filters: {
      ref_pedido: "",
      entidad: "",
      startDate: "",
      endDate: "",
    },
    filterKey: [],
    selectedHeaders: [],
    startDateMenu: false,
    endDateMenu: false,
  }),

  computed: {
    ...mapState("transacciones", ["transacciones"]),
    filteredData() {
      return this.transacciones
        .map((transaction) => {
          return {
            ...transaction,
            cliente_id: this.isNumeric(transaction.cliente_id)
              ? "Cliente Test"
              : transaction.cliente_id,
            entidad: this.isNumeric(transaction.entidad_id)
              ? "Entidad Test"
              : transaction.entidad || "-",
            ente: transaction.ente || "-",
            cliente: transaction.cliente || "-",
          };
        })
        .filter((transaction) => {
          const clienteFilter = this.filters.cliente_id
            ? this.filters.cliente_id.toLowerCase()
            : "";
          const entidadFilter = this.filters.entidad
            ? this.filters.entidad.toLowerCase()
            : "";
          const refPedidoFilter = this.filters.ref_pedido
            ? this.filters.ref_pedido.toLowerCase()
            : "";

          return (
            (!refPedidoFilter ||
              transaction.ref_pedido.toLowerCase().includes(refPedidoFilter)) &&
            (!entidadFilter ||
              transaction.entidad.toLowerCase().includes(entidadFilter)) &&
            (!this.filters.startDate ||
              new Date(transaction.fecha) >=
                new Date(this.filters.startDate)) &&
            (!this.filters.endDate ||
              new Date(transaction.fecha) <= new Date(this.filters.endDate))
          );
        });
    },
    showHeaders() {
      return this.headers.filter((s) => this.selectedHeaders.includes(s));
    },
  },

  created() {
    this.selectedHeaders = this.headers;
    this.fetchTransacciones();
  },

  methods: {
    ...mapActions("transacciones", ["fetchTransacciones"]),
    isNumeric(value) {
      return /^\d+$/.test(value);
    },
    viewDetalle(item) {
      this.$router.push({
        name: "transacciones_detalle",
        params: { transaccion_id: item.id },
      });
    },
    viewFactura(item, tipo) {
      let pdfPath = "";
      if (tipo === "definitiva") {
        pdfPath = require("@/assets/facturaAnmac.jpg");
      } else if (tipo === "borrador") {
        pdfPath = require("@/assets/facturaActivo.jpg");
      }
      window.open(pdfPath, "_blank");
    },
    filterByRefPedido(item) {
      return this.$filterBy(item, "ref_pedido");
    },
    filterByEstado(item) {
      return this.$filterBy(item, "estado");
    },
    filterByTipo(item) {
      return this.$filterBy(item, "tipo");
    },
    filterByEnte(item) {
      return this.$filterBy(item, "ente");
    },
    filterByCliente(item) {
      return this.$filterBy(item, "cliente_id");
    },
    filterByFecha(item) {
      return this.$filterBy(item, "fecha");
    },
    filterByTotal(item) {
      return this.$filterBy(item, "total");
    },
    filterByMetodoPago(item) {
      return this.$filterBy(item, "metodo_pago");
    },
    clearStartDate() {
      this.filters.startDate = "";
    },
    clearEndDate() {
      this.filters.endDate = "";
    },
    clearField(field) {
      this.filters[field] = "";
    },
    alert(title, msg) {
      Swal.alert(title, msg);
    },
  },
};
</script>

<style>
.carritoMsg__div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 9rem;
  font-weight: bold;
  font-size: 0.85rem;
}
.carrito__div {
  overflow-y: auto;
  max-height: 41rem;
}
.data__table {
  overflow-y: auto;
  max-height: 50.75rem;
}
.div_total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
}

.routerLink_total {
  background-color: #011f5b;
  color: white !important;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  text-decoration: none;
  border-radius: 0.5rem;
}

.text--primary {
  color: blue;
}
</style>
